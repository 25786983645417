import kamzikHorskyMp3 from './animals/Zvuky/Kamzik_horsky.mp3';
import kamzikHorskyObr from './animals/Obrazky/Kamzik_horsky.jpg';
import kockaDivokaMp3 from './animals/Zvuky/Kocka_divoka.mp3';
import kockaDivokaObr from './animals/Obrazky/Kocka_divoka.jpg';
import liskaObecnaMp3 from './animals/Zvuky/Liska_obecna.mp3';
import liskaObecnaObr from './animals/Obrazky/Liska_obecna2.jpeg';
import vlkObecnyMp3 from './animals/Zvuky/Vlk_obecny.mp3';
import vlkObecnyObr from './animals/Obrazky/Vlk_obecny.jpg';
import brhlikLesniMp3 from './animals/Zvuky/Brhlik_lesni.mp3';
import brhlikLesniObr from './animals/Obrazky/Brhlik_lesni.jpg';
import cervenkaObecnaMp3 from './animals/Zvuky/Cervenka_obecna.mp3';
import cervenkaObecnaObr from './animals/Obrazky/Cervenka_obecna.jpg';
import cizekLesniMp3 from './animals/Zvuky/Cizek_lesni.mp3';
import cizekLesniObr from './animals/Obrazky/Cizek_lesni.jpg';
import dlaskTlustozobyMp3 from './animals/Zvuky/Dlask_tlustozoby.mp3';
import dlaskTlustozobyObr from './animals/Obrazky/Dlask_tlustozoby.jpg';
import drozdKvicalaMp3 from './animals/Zvuky/Drozd_kvicala.mp3';
import drozdKvicalaObr from './animals/Obrazky/Drozd_kvicala.jpg';
import mlynarikDlouhoocasyMp3 from './animals/Zvuky/Mlynarik_dlouhoocasy.mp3';
import mlynarikDlouhoocasyObr from './animals/Obrazky/Mlynarik_dlouhoocasy.jpg';
import sykoraKonadraMp3 from './animals/Zvuky/Sykora_konadra.mp3';
import sykoraKonadraObr from './animals/Obrazky/Sykora_konadra.jpg';
import kosCernyMp3 from './animals/Zvuky/Kos_cerny.mp3';
import kosCernyObr from './animals/Obrazky/Kos_cerny.jpg';
import vyrVelkyMp3 from './animals/Zvuky/Vyr_velky.mp3';
import vyrVelkyObr from './animals/Obrazky/Vyr_velky.jpg';
import zajicPolniMp3 from './animals/Zvuky/Zajic_polni.mp3';
import zajicPolniObr from './animals/Obrazky/Zajic_polni.jpg';
import kozaBezoarovaMp3 from './animals/Zvuky/Koza_bezoarova.mp3';
import kozaBezoarovaObr from './animals/Obrazky/Koza_bezoarova.jpg';
import lasiceKolcavaMp3 from './animals/Zvuky/Lasice_kolcava.mp3';
import lasiceKolcavaObr from './animals/Obrazky/Lasice_kolcava.jpg';
import danekSkvrnityMp3 from './animals/Zvuky/Danek_skvrnity.mp3';
import danekSkvrnityObr from './animals/Obrazky/Danek_skvrnity.jpg';
import jelenSikaMp3 from './animals/Zvuky/Jelen_sika.mp3';
import jelenSikaObr from './animals/Obrazky/Jelen_sika.jpg';
import srnecObecnyMp3 from './animals/Zvuky/Srnec_obecny.mp3';
import srnecObecnyObr from './animals/Obrazky/Srnec_obecny.jpg';
import sykoraModrinkaMp3 from './animals/Zvuky/Sykora_modrinka.mp3';
import sykoraModrinkaObr from './animals/Obrazky/Sykora_modrinka.jpg';
import jestrabLesniMp3 from './animals/Zvuky/Jestrab_lesni.mp3';
import jestrabLesniObr from './animals/Obrazky/Jestrab_lesni.jpg';
import hrdlickaZahradniMp3 from './animals/Zvuky/Hrdlicka_zahradni.mp3';
import hrdlickaZahradniObr from './animals/Obrazky/Hrdlicka_zahradni.jpg';
import sojkaObecnaMp3 from './animals/Zvuky/Sojka_obecna.mp3';
import sojkaObecnaObr from './animals/Obrazky/Sojka_obecna.jpg';
import zvonekZelenyMp3 from './animals/Zvuky/Zvonek_zeleny.mp3';
import zvonekZelenyObr from './animals/Obrazky/Zvonek_zeleny.jpg';
import strakapoudVelkyMp3 from './animals/Zvuky/Strakapoud_velky.mp3';
import strakapoudVelkyObr from './animals/Obrazky/Strakapoud_velky.jpg';
import kalousUsatyMp3 from './animals/Zvuky/Kalous_usaty.mp3';
import kalousUsatyObr from './animals/Obrazky/Kalous_usaty.jpg';
import penkavaObecnaMp3 from './animals/Zvuky/Penkava_obecna.mp3';
import penkavaObecnaObr from './animals/Obrazky/Penkava_obecna.jpg';
import kaneLesniMp3 from './animals/Zvuky/Kane_lesni.mp3';
import kaneLesniObr from './animals/Obrazky/Kane_lesni.jpg';
import vydraRicniMp3 from './animals/Zvuky/Vydra_ricni.mp3';
import vydraRicniObr from './animals/Obrazky/Vydra_ricni.jpg';
import veverkaObecnaMp3 from './animals/Zvuky/Veverka_obecna.mp3';
import veverkaObecnaObr from './animals/Obrazky/Veverka_obecna.jpg';
import ondatraPizmovaMp3 from './animals/Zvuky/Ondatra_pizmova.mp3';
import ondatraPizmovaObr from './animals/Obrazky/Ondatra_pizmova.jpg';
import praseDivokeMp3 from './animals/Zvuky/Prase_divoke.mp3';
import praseDivokeObr from './animals/Obrazky/Prase_divoke.jpg';
import losEvropskyMp3 from './animals/Zvuky/Los_evropsky.mp3';
import losEvropskyObr from './animals/Obrazky/Los_evropsky.jpg';
import jelenLesniMp3 from './animals/Zvuky/Jelen_lesni.mp3';
import jelenLesniObr from './animals/Obrazky/Jelen_lesni.jpg';
import tchorTmavyMp3 from './animals/Zvuky/Tchor_tmavy.mp3';
import tchorTmavyObr from './animals/Obrazky/Tchor_tmavy.jpg';
import kunaLesniMp3 from './animals/Zvuky/Kuna_lesni.mp3';
import kunaLesniObr from './animals/Obrazky/Kuna_lesni.jpg';
import lasiceHranostajMp3 from './animals/Zvuky/Lasice_hranostaj.mp3';
import lasiceHranostajObr from './animals/Obrazky/Lasice_hranostaj.jpg';
import krahujecObecnyMp3 from './animals/Zvuky/Krahujec_obecny.mp3';
import krahujecObecnyObr from './animals/Obrazky/Krahujec_obecny.jpg';
import vrabecPolniMp3 from './animals/Zvuky/Vrabec_polni.mp3';
import vrabecPolniObr from './animals/Obrazky/Vrabec_polni.jpg';
import kulisekNejmensiMp3 from './animals/Zvuky/Kulisek_nejmensi.mp3';
import kulisekNejmensiObr from './animals/Obrazky/Kulisek_nejmensi.jpg';
import stehlikObecnyMp3 from './animals/Zvuky/Stehlik_obecny.mp3';
import stehlikObecnyObr from './animals/Obrazky/Stehlik_obecny.jpg';
import sykoraBabkaMp3 from './animals/Zvuky/Sykora_babka.mp3';
import sykoraBabkaObr from './animals/Obrazky/Sykora_babka.jpg';
import pustikObecnyMp3 from './animals/Zvuky/Pustik_obecny.mp3';
import pustikObecnyObr from './animals/Obrazky/Pustik_obecny.jpg';
import sycRousnyMp3 from './animals/Zvuky/Syc_rousny.mp3';
import sycRousnyObr from './animals/Obrazky/Syc_rousny.jpg';
import strakaObecnaMp3 from './animals/Zvuky/Straka_obecna.mp3';
import strakaObecnaObr from './animals/Obrazky/Straka_obecna.jpg';
import jezevecLesniMp3 from './animals/Zvuky/Jezevec_lesni.mp3';
import jezevecLesniObr from './animals/Obrazky/Jezevec_lesni.jpg';
import jezekZapadniMp3 from './animals/Zvuky/Jezek_zapadni.mp3';
import jezekZapadniObr from './animals/Obrazky/Jezek_zapadni.jpg';
import rysOstrovidMp3 from './animals/Zvuky/Rys_ostrovid.mp3';
import rysOstrovidObr from './animals/Obrazky/Rys_ostrovid.jpeg';

export const questions = [
  [
    {
      otazka: 'Co je to za zvíře?',
      typ: 'zvuk',
      soubor: kamzikHorskyMp3,
      odpovedi: [
        {
          nazev: 'Kamzík horský',
          spravne: true,
        },
        {
          nazev: 'Liška obecná',
          spravne: false,
        },
        {
          nazev: 'Lasice hranostaj',
          spravne: false,
        },
        {
          nazev: 'Ondatra pižmová',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'V jeho žaludku se často nachází tzv. bezoárové koule, které vznikají slepením mé srsti a pryskyřice z okusovaných klečí. Mají velikost ping-pongových míčku a jsou tvrdé jako kámen.',
        obrazek: kamzikHorskyObr,
      },
    },
    {
      otazka: 'Co je to za zvíře?',
      typ: 'zvuk',
      soubor: kockaDivokaMp3,
      odpovedi: [
        {
          nazev: 'Kočka divoká',
          spravne: true,
        },
        {
          nazev: 'Kuna lesní',
          spravne: false,
        },
        {
          nazev: 'Vlk obecný',
          spravne: false,
        },
        {
          nazev: 'Šakal obecný',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Výborně šplhá po stromech, ale pokud chce slézt dolů, musí jít pozpátku, jelikož nedokáže lézt hlavou dolů.',
        obrazek: kockaDivokaObr,
      },
    },
    {
      otazka: 'Co je to za zvíře?',
      typ: 'zvuk',
      soubor: liskaObecnaMp3,
      odpovedi: [
        {
          nazev: 'Liška obecná',
          spravne: true,
        },
        {
          nazev: 'Psík mývalovitý',
          spravne: false,
        },
        {
          nazev: 'Králík divoký',
          spravne: false,
        },
        {
          nazev: 'Šakal obecný',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Ačkoli si většina lidí myslí, že liška jen "štěká", dokáže vydat zvuky v rozsahu pěti oktáv. Zajímavý je také její způsob lovu, pokud loví myším podobnou zvěř, nejprve určí místo výskytu sluchem, pak zaútočí až 5 m dlouhým skokem, v letu kormidlují oháňkou a zvysoka dopadají na kořist. V zimě je schopna liška slyšet svoji kořist i pod sněhem a proskočit při jejím lovu sněhovou vrstvu silnou několik desítek centimetrů.',
        obrazek: liskaObecnaObr,
      },
    },
    {
      otazka: 'Co je to za zvíře?',
      typ: 'zvuk',
      soubor: vlkObecnyMp3,
      odpovedi: [
        {
          nazev: 'Vlk obecný',
          spravne: true,
        },
        {
          nazev: 'Šakal obecný',
          spravne: false,
        },
        {
          nazev: 'Liška obecná',
          spravne: false,
        },
        {
          nazev: 'Psík mývalovitý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Vlčí mláďata se rodí s modrýma očima, které se během 8-16 týdnů změní na žluto-zlaté.',
        obrazek: vlkObecnyObr,
      },
    },
  ],
  [
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: brhlikLesniMp3,
      odpovedi: [
        {
          nazev: 'Brhlík lesní',
          spravne: true,
        },
        {
          nazev: 'Kos černý',
          spravne: false,
        },
        {
          nazev: 'Krahujec obecný',
          spravne: false,
        },
        {
          nazev: 'Mlynařík dlouhoocasý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost: 'Jako jediný pták dokáže lézt po stromě hlavou dolů.',
        obrazek: brhlikLesniObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: cervenkaObecnaMp3,
      odpovedi: [
        {
          nazev: 'Červenka obecná',
          spravne: true,
        },
        {
          nazev: 'Zvonek zelený',
          spravne: false,
        },
        {
          nazev: 'Vrabec polní',
          spravne: false,
        },
        {
          nazev: 'Stehlík obecný',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Červenku obecnou vyhlásila Česká společnost ornitologická Ptákem roku 2016.',
        obrazek: cervenkaObecnaObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: cizekLesniMp3,
      odpovedi: [
        {
          nazev: 'Čížek lesní',
          spravne: true,
        },
        {
          nazev: 'Sýkora modřinka',
          spravne: false,
        },
        {
          nazev: 'Sýkora koňadra',
          spravne: false,
        },
        {
          nazev: 'Pěnkava obecná',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Hnízdo čížka lze nalézt jen velmi nesnadno, protože pták je staví dvacet i více metrů vysoko v koruně smrku. Dokonce vznikla i romantická pověst, že ptáček přidává do svého hnízda divotvorný kamínek, a ten činí hnízdo pro lidské pozorovatele naprosto nenalezitelné.',
        obrazek: cizekLesniObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: dlaskTlustozobyMp3,
      odpovedi: [
        {
          nazev: 'Dlask tlustozobý',
          spravne: true,
        },
        {
          nazev: 'Brhlík lesní',
          spravne: false,
        },
        {
          nazev: 'Drozd kvíčala',
          spravne: false,
        },
        {
          nazev: 'Pěnkava obecná',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Dlaskův zobák mění barvu - v létě je šedočerný a v zimě slonovinově bílý nebo světle žlutohnědý.',
        obrazek: dlaskTlustozobyObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: drozdKvicalaMp3,
      odpovedi: [
        {
          nazev: 'Drozd kvíčala',
          spravne: true,
        },
        {
          nazev: 'Stehlík obecný',
          spravne: false,
        },
        {
          nazev: 'Straka obecná',
          spravne: false,
        },
        {
          nazev: 'Strakapoud velký',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'V zimě při nedostatku potravy je kvíčala poměrně agresivní vůči ptákům své velikosti, jiné drozdy či kosy nepouští ke krmení a naopak je pronásleduje a zahání.',
        obrazek: drozdKvicalaObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: mlynarikDlouhoocasyMp3,
      odpovedi: [
        {
          nazev: 'Mlynařík dlouhoocasý',
          spravne: true,
        },
        {
          nazev: 'Slavík obecný',
          spravne: false,
        },
        {
          nazev: 'Rákosník zpěvný',
          spravne: false,
        },
        {
          nazev: 'Skřivánek malý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'S krmením mláďat často vypomáhá až 8 jiných dospělých mlynaříků.',
        obrazek: mlynarikDlouhoocasyObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: sykoraKonadraMp3,
      odpovedi: [
        {
          nazev: 'Sýkora koňadra',
          spravne: true,
        },
        {
          nazev: 'Žluna zelená',
          spravne: false,
        },
        {
          nazev: 'Lejsek šedý',
          spravne: false,
        },
        {
          nazev: 'Sluka lesní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Kromě přirozených stromových dutin a vyvěšených budek, obsazují i nejpodivnější dutiny: zahnízdí v nepoužívané pumpě, kovové trubce, schránce na dopisy, kropicí konvi či odhozené plechovce nebo staré botě.',
        obrazek: sykoraKonadraObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: kosCernyMp3,
      odpovedi: [
        {
          nazev: 'Kos černý',
          spravne: true,
        },
        {
          nazev: 'Budníček lesní',
          spravne: false,
        },
        {
          nazev: 'Pěnice černohlavá',
          spravne: false,
        },
        {
          nazev: 'Vrabec domácí',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Kos je opeřenec oplývající odvahou a při hájení teritoria nebo obraně hnízda se bez váhání postaví třeba i poštolce. Proto vzniklo úsloví "být na něco kos", které je užívané pro označení nebojácného člověka.',
        obrazek: kosCernyObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle zvuku?',
      typ: 'zvuk',
      soubor: vyrVelkyMp3,
      odpovedi: [
        {
          nazev: 'Výr velký',
          spravne: true,
        },
        {
          nazev: 'Výreček malý',
          spravne: false,
        },
        {
          nazev: 'Sova pálená',
          spravne: false,
        },
        {
          nazev: 'Puštík bělavý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Ne nadarmo je výr nazýván velkým. Délka jeho těla je 60–75 cm, rozpětí křídel 160–188 cm.',
        obrazek: vyrVelkyObr,
      },
    },
  ],
  [
    {
      otazka: 'Víš jaké zvíře je na nahrávce?',
      typ: 'zvuk',
      soubor: zajicPolniMp3,
      odpovedi: [
        {
          nazev: 'Zajíc polní',
          spravne: true,
        },
        {
          nazev: 'Kočka divoká',
          spravne: false,
        },
        {
          nazev: 'Liška obecná',
          spravne: false,
        },
        {
          nazev: 'Lasice kolčava',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Zajíc je velmi zdatným běžcem, běhá běžně 40 km/h a v nebezpečí až 74 km/h a dobře kličkuje. Jelikož má přední nohy oproti zadním kratší, je paradoxně rychlejší při běhu do kopce než z kopce. Výborně skáče do výšky přes 1 m a do dálky až 6 m.',
        obrazek: zajicPolniObr,
      },
    },
    {
      otazka: 'Víš jaké zvíře je na nahrávce?',
      typ: 'zvuk',
      soubor: kozaBezoarovaMp3,
      odpovedi: [
        {
          nazev: 'Koza bezoárová',
          spravne: true,
        },
        {
          nazev: 'Hryzec vodní',
          spravne: false,
        },
        {
          nazev: 'Mýval severní',
          spravne: false,
        },
        {
          nazev: 'Norek evropský',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Nejspíše se jedná o předka domácích koz. K zdomácnění došlo již před 6 000-8 000 lety př. n. l., možná i dříve a v současné době je známo na 300 různých plemen.',
        obrazek: kozaBezoarovaObr,
      },
    },
    {
      otazka: 'Víš jaké zvíře je na nahrávce?',
      typ: 'zvuk',
      soubor: lasiceKolcavaMp3,
      odpovedi: [
        {
          nazev: 'Lasice kolčava',
          spravne: true,
        },
        {
          nazev: 'Kuna skalní',
          spravne: false,
        },
        {
          nazev: 'Hryzec vodní',
          spravne: false,
        },
        {
          nazev: 'Králík divoký',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Ocas na rozdíl od hranostaje nemá špičku černou. Severně žijící populace na zimu přebarvují doběla, jižněji žijící obvykle ne. V České republice na zimu lasice většinou nepřebarvuje, jen zesvětlá.',
        obrazek: lasiceKolcavaObr,
      },
    },
  ],
  [
    {
      otazka: 'Kdo vydává tento zvuk?',
      typ: 'zvuk',
      soubor: danekSkvrnityMp3,
      odpovedi: [
        {
          nazev: 'Daněk skvrnitý',
          spravne: true,
        },
        {
          nazev: 'Los evropský',
          spravne: false,
        },
        {
          nazev: 'Jelen sika',
          spravne: false,
        },
        {
          nazev: 'Srnec obecný',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Po narození mládě zalehne nehybně do husté vegetace, kde zůstává bez hlesu ležet, maskováno barvou svého kožíšku proti predátorům. Matka se k němu v intervalech vrací, aby ho nakojila a případně odvedla na nové místo k zalehnutí.',
        obrazek: danekSkvrnityObr,
      },
    },
    {
      otazka: 'Kdo vydává tento zvuk?',
      typ: 'zvuk',
      soubor: jelenSikaMp3,
      odpovedi: [
        {
          nazev: 'Jelen sika',
          spravne: true,
        },
        {
          nazev: 'Osel domácí',
          spravne: false,
        },
        {
          nazev: 'Lama alpaka',
          spravne: false,
        },
        {
          nazev: 'Lama krotká',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Jelen sika není původním druhem. Do Čech byl dovezen na počátku minulého století z Japonska. Z plzeňských obor se díky své schopnosti přeskakovat až dvoumetrové překážky dostal do volné přírody.',
        obrazek: jelenSikaObr,
      },
    },
    {
      otazka: 'Kdo vydává tento zvuk?',
      typ: 'zvuk',
      soubor: srnecObecnyMp3,
      odpovedi: [
        {
          nazev: 'Srnec obecný',
          spravne: true,
        },
        {
          nazev: 'Rys ostrovid',
          spravne: false,
        },
        {
          nazev: 'Liška obecná',
          spravne: false,
        },
        {
          nazev: 'Muflon evropský',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Samci mají relativně krátké parůžky, které mohou u jedinců v dobrých podmínkách dorůstat až do délky 25 cm. Každým rokem, v rozmezí od října do listopadu, shazují samci své parůžky a na jaře příštího roku jim narůstají nové, které jsou zpočátku pokryté jakousi sametovou vrstvou, která se nazývá lýčí a vyživuje parůžek při vývoji a růstu.',
        obrazek: srnecObecnyObr,
      },
    },
  ],
  [
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: sykoraModrinkaMp3,
      odpovedi: [
        {
          nazev: 'Sýkora modřinka',
          spravne: true,
        },
        {
          nazev: 'Krkavec velký',
          spravne: false,
        },
        {
          nazev: 'Havran polní',
          spravne: false,
        },
        {
          nazev: 'Skřivan polní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Je velmi aktivní, na stromech se pohybuje kodrcavými poskoky, při hledání potravy často visí hlavou dolů.',
        obrazek: sykoraModrinkaObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: jestrabLesniMp3,
      odpovedi: [
        {
          nazev: 'Jestřáb lesní',
          spravne: true,
        },
        {
          nazev: 'Poštolka obecná',
          spravne: false,
        },
        {
          nazev: 'Káně rousná',
          spravne: false,
        },
        {
          nazev: 'Orel nejmenší',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Svou kořist vyhlíží za letu nevysoko nad porostem nebo vsedě z pozorovatelny a chytá ji po prudkém výpadu do napřažených pařátů.',
        obrazek: jestrabLesniObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: hrdlickaZahradniMp3,
      odpovedi: [
        {
          nazev: 'Hrdlička zahradní',
          spravne: true,
        },
        {
          nazev: 'Sova pálená',
          spravne: false,
        },
        {
          nazev: 'Puštík bradatý',
          spravne: false,
        },
        {
          nazev: 'Sýček obecný',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Samička do hnízda snáší dvě bílá vajíčka, na kterých sedí 14 - 16 dnů. Po vylíhnutí oba rodiče krmí mláďata tzv. holubím mlékem (natrávenou směsí potravy).',
        obrazek: hrdlickaZahradniObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: sojkaObecnaMp3,
      odpovedi: [
        {
          nazev: 'Sojka obecná',
          spravne: true,
        },
        {
          nazev: 'Krkavec velký',
          spravne: false,
        },
        {
          nazev: 'Straka obecná',
          spravne: false,
        },
        {
          nazev: 'Vrána obecná',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Mláďata opouštějí hnízdo cca po 22 dnech, ale po dobu 3 týdnů je ještě rodiče přikrmují.',
        obrazek: sojkaObecnaObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: zvonekZelenyMp3,
      odpovedi: [
        {
          nazev: 'Zvonek zelený',
          spravne: true,
        },
        {
          nazev: 'Sýkora uhelníček',
          spravne: false,
        },
        {
          nazev: 'Sýkora parukářka',
          spravne: false,
        },
        {
          nazev: 'Vlaštovka obecná',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Své jméno získal díky hlasu, který obsahuje řadu zvonivých tónů.',
        obrazek: zvonekZelenyObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: strakapoudVelkyMp3,
      odpovedi: [
        {
          nazev: 'Strakapoud velký',
          spravne: true,
        },
        {
          nazev: 'Tetřev hlušec',
          spravne: false,
        },
        {
          nazev: 'Tetřívek obecný',
          spravne: false,
        },
        {
          nazev: 'Datel černý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Díky svým velmi silným krčním svalům je schopen do dřeva narážet velkou rychlostí, proto má i velmi silnou lebku, která chrání jeho mozek před zpětnými nárazy.',
        obrazek: strakapoudVelkyObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: kalousUsatyMp3,
      odpovedi: [
        {
          nazev: 'Kalous ušatý',
          spravne: true,
        },
        {
          nazev: 'Sovice krahujová',
          spravne: false,
        },
        {
          nazev: 'Kalous pustovka',
          spravne: false,
        },
        {
          nazev: 'Sovice sněžní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Svou kořist dokáže lokalizovat i v naprosté tmě. Významně mu napomáhá asymetrické umístění ušních dutin, kdy levá je výrazně výše položená než ta pravá.',
        obrazek: kalousUsatyObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: penkavaObecnaMp3,
      odpovedi: [
        {
          nazev: 'Pěnkava obecná',
          spravne: true,
        },
        {
          nazev: 'Červenka obecná',
          spravne: false,
        },
        {
          nazev: 'Žluna zelená',
          spravne: false,
        },
        {
          nazev: 'Skřivan polní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Samice pěnkavy obecné při stavbě hnízda slétne na zem pro materiál a vylétne s ním k hnízdu až 1300x.',
        obrazek: penkavaObecnaObr,
      },
    },
    {
      otazka: 'Čí je tento hlas?',
      typ: 'zvuk',
      soubor: kaneLesniMp3,
      odpovedi: [
        {
          nazev: 'Káně lesní',
          spravne: true,
        },
        {
          nazev: 'Káně rousná',
          spravne: false,
        },
        {
          nazev: 'Moták lužní',
          spravne: false,
        },
        {
          nazev: 'Luňák červený',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Nejstarší doložený věk káněte ve volné přírodě je 26 let, v zajetí 30 let.',
        obrazek: kaneLesniObr,
      },
    },
  ],
  [
    {
      otazka: 'Pokud slyšíš tento zvuk, kdo je s tebou v lese?',
      typ: 'zvuk',
      soubor: vydraRicniMp3,
      odpovedi: [
        {
          nazev: 'Vydra říční',
          spravne: true,
        },
        {
          nazev: 'Hraboš polní',
          spravne: false,
        },
        {
          nazev: 'Plch lesní',
          spravne: false,
        },
        {
          nazev: 'Norek evropský',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Vydra říční je skvělý plavec a potápěč. Pod vodou vydrží déle než 5 minut (pravděpodobně až 7 nebo 8 minut), ale obvykle se vynoří během jedné minuty. Uplavat zvládne až 400 metrů bez jediného nadechnutí, obvykle plave rychlostí 2,2–5,4 km/h (někdy až 7 až 15 km/h). Takového výkonu dosáhne díky hadovitým pohybům těla. Ponořit se dokáže do hloubky 9–10 metrů.',
        obrazek: vydraRicniObr,
      },
    },
    {
      otazka: 'Pokud slyšíš tento zvuk, kdo je s tebou v lese?',
      typ: 'zvuk',
      soubor: veverkaObecnaMp3,
      odpovedi: [
        {
          nazev: 'Veverka obecná',
          spravne: true,
        },
        {
          nazev: 'Bobr evropský',
          spravne: false,
        },
        {
          nazev: 'Norek evropský',
          spravne: false,
        },
        {
          nazev: 'Vydra říční',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Mláďata se rodí slepá a téměř holá a po narození váží 10 až 15 g. Plně osrstěná jsou po 21 dnech života, oči se jim otevřou po čtyřech týdnech a chrup se jim plně vyvíjí až po 42 dnech, kdy začínají požírat pevnou stravu.',
        obrazek: veverkaObecnaObr,
      },
    },
    {
      otazka: 'Pokud slyšíš tento zvuk, kdo je s tebou v lese?',
      typ: 'zvuk',
      soubor: ondatraPizmovaMp3,
      odpovedi: [
        {
          nazev: 'Ondatra pižmová',
          spravne: true,
        },
        {
          nazev: 'Vydra říční',
          spravne: false,
        },
        {
          nazev: 'Bobr evropský',
          spravne: false,
        },
        {
          nazev: 'Králík divoký',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Ondatra není původní druh. V Čechách ji vysadil v roce 1905 kníže Colloredo-Mansfeld na svém panství v Dobříši pro její vysoce ceněnou kůži.',
        obrazek: ondatraPizmovaObr,
      },
    },
  ],
  [
    {
      otazka: 'Poznáš, kdo to je?',
      typ: 'zvuk',
      soubor: praseDivokeMp3,
      odpovedi: [
        {
          nazev: 'Prase divoké',
          spravne: true,
        },
        {
          nazev: 'Jelen lesní',
          spravne: false,
        },
        {
          nazev: 'Daněk skvrnitý',
          spravne: false,
        },
        {
          nazev: 'Los evropský',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Divoká prasata si libují v dotyku s ostatními jedinci. Spí spolu, třou se o sebe a vzájemně se čistí. Často leží poskládána jedno přes druhé.',
        obrazek: praseDivokeObr,
      },
    },
    {
      otazka: 'Poznáš, kdo to je?',
      typ: 'zvuk',
      soubor: losEvropskyMp3,
      odpovedi: [
        {
          nazev: 'Los evropský',
          spravne: true,
        },
        {
          nazev: 'Srnec obecný',
          spravne: false,
        },
        {
          nazev: 'Kuna lesní',
          spravne: false,
        },
        {
          nazev: 'Kamzík horský',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Jeho zvláštně vypadající mulec má hlavně praktické využití. Ohřívá se v něm ledový vzduch a lépe se jím obírají i splývavé části vodních rostlin.',
        obrazek: losEvropskyObr,
      },
    },
    {
      otazka: 'Poznáš, kdo to je?',
      typ: 'zvuk',
      soubor: jelenLesniMp3,
      odpovedi: [
        {
          nazev: 'Jelen lesní',
          spravne: true,
        },
        {
          nazev: 'Medvěd grizzly',
          spravne: false,
        },
        {
          nazev: 'Medvěd hnědý',
          spravne: false,
        },
        {
          nazev: 'Muflon evropský',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Dospělému jelenovi se v určitém věku vytvoří v srdci kůstka ve tvaru kříže, které se říká hubertka.',
        obrazek: jelenLesniObr,
      },
    },
  ],
  [
    {
      otazka: 'Poznáš zvíře podle jeho hlasu?',
      typ: 'zvuk',
      soubor: tchorTmavyMp3,
      odpovedi: [
        {
          nazev: 'Tchoř tmavý',
          spravne: true,
        },
        {
          nazev: 'Jezevec lesní',
          spravne: false,
        },
        {
          nazev: 'Lasice kolčava',
          spravne: false,
        },
        {
          nazev: 'Bobr evropský',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Přes den se ukrývá v hromadách kamení či větví, využívá i kůlny a stodoly, rád osidluje opuštěné nory králíků nebo jezevců. Potravu loví zejména v noci. Dobře běhá, lezení po stromech ani plavání mu nečiní potíže. Tchoř je teritoriální, samci si udržují stálý revír o výměře 3–5 km2, který brání před jinými samci. Také samice si udržují revíry, ty jsou ale menší než revíry samců a mohou se i překrývat. Hranice teritoria si zvířata značí výměškem análních pachových žláz. ',
        obrazek: tchorTmavyObr,
      },
    },
    {
      otazka: 'Poznáš zvíře podle jeho hlasu?',
      typ: 'zvuk',
      soubor: kunaLesniMp3,
      odpovedi: [
        {
          nazev: 'Kuna lesní',
          spravne: true,
        },
        {
          nazev: 'Kočka divoká',
          spravne: false,
        },
        {
          nazev: 'Liška obecná',
          spravne: false,
        },
        {
          nazev: 'Tchoř tmavý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Má skvěle vyvinutý sluch a zrak, kterým dokáže zaznamenat i malé druhy živočichů. Je velmi mrštná a doskočí až 4 metry daleko. Své teritorium si značí velmi páchnoucími výměšky pachových, análních a břišních žláz.',
        obrazek: kunaLesniObr,
      },
    },

    {
      otazka: 'Poznáš zvíře podle jeho hlasu?',
      typ: 'zvuk',
      soubor: lasiceHranostajMp3,
      odpovedi: [
        {
          nazev: 'Lasice hranostaj',
          spravne: true,
        },
        {
          nazev: 'Veverka obecná',
          spravne: false,
        },
        {
          nazev: 'Kuna lesní',
          spravne: false,
        },
        {
          nazev: 'Sysel obecný',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Zbarvení hranostaje je v létě hnědé (zahrnuje poměrně rozsáhlou škálu od světle až po tmavě hnědou barvu) s bílým břichem, které v zimě nahrazuje hustší čistě bílý kožich. Zimní kožešina hranostaje byla vysoce ceněná, jelikož se používala na výrobu tzv. hermelínu, což je ozdoba slavnostních rouch.',
        obrazek: lasiceHranostajObr,
      },
    },
  ],
  [
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: krahujecObecnyMp3,
      odpovedi: [
        {
          nazev: 'Krahujec obecný',
          spravne: true,
        },
        {
          nazev: 'Jestřáb lesní',
          spravne: false,
        },
        {
          nazev: 'Poštolka obecná',
          spravne: false,
        },
        {
          nazev: 'Drozd kvíčala',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Součástí toku krahujce jsou svatební lety, při kterých oba ptáci za širokých rozmachů křídel vystoupají vzhůru a poté prudce klesají.',
        obrazek: krahujecObecnyObr,
      },
    },
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: vrabecPolniMp3,
      odpovedi: [
        {
          nazev: 'Vrabec polní',
          spravne: true,
        },
        {
          nazev: 'Vrabec domácí',
          spravne: false,
        },
        {
          nazev: 'Pěnice černohlavá',
          spravne: false,
        },
        {
          nazev: 'Skřivan lesní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost: 'Nejvyšší známý věk vrabce je 13 let.',
        obrazek: vrabecPolniObr,
      },
    },
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: kulisekNejmensiMp3,
      odpovedi: [
        {
          nazev: 'Kulíšek nejmenší',
          spravne: true,
        },
        {
          nazev: 'Stehlík obecný',
          spravne: false,
        },
        {
          nazev: 'Krahujec obecný',
          spravne: false,
        },
        {
          nazev: 'Puštík bělavý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Aby nestrádal, tak si dělá v dutinách zásoby potravy z ulovené kořisti.',
        obrazek: kulisekNejmensiObr,
      },
    },
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: stehlikObecnyMp3,
      odpovedi: [
        {
          nazev: 'Stehlík obecný',
          spravne: true,
        },
        {
          nazev: 'Sýkora parukářka',
          spravne: false,
        },
        {
          nazev: 'Špaček obecný',
          spravne: false,
        },
        {
          nazev: 'Žluva hajní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Samečkové začínají zpívat už v únoru, kdy se ještě zdržují v zimních hejnech. Začátkem dubna se hejna rozpadají a začínají utvářet jednotlivé páry.',
        obrazek: stehlikObecnyObr,
      },
    },
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: sykoraBabkaMp3,
      odpovedi: [
        {
          nazev: 'Sýkora babka',
          spravne: true,
        },
        {
          nazev: 'Strnad zahradní',
          spravne: false,
        },
        {
          nazev: 'Bramborníček hnědý',
          spravne: false,
        },
        {
          nazev: 'Skřivánek malý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Zajímavostí je, že do zobáku si najednou dokáže nabrat i několik kousků semen.',
        obrazek: sykoraBabkaObr,
      },
    },
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: pustikObecnyMp3,
      odpovedi: [
        {
          nazev: 'Puštík obecný',
          spravne: true,
        },
        {
          nazev: 'Výreček malý',
          spravne: false,
        },
        {
          nazev: 'Sova pálená',
          spravne: false,
        },
        {
          nazev: 'Kulíšek nejmenší',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Jako jedna z mála sov má celočerné oči. Je známý také pro svou nebývalou urputnost při obraně hnízda.',
        obrazek: pustikObecnyObr,
      },
    },
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: sycRousnyMp3,
      odpovedi: [
        {
          nazev: 'Sýc rousný',
          spravne: true,
        },
        {
          nazev: 'Výr velký',
          spravne: false,
        },
        {
          nazev: 'Kalous pustovka',
          spravne: false,
        },
        {
          nazev: 'Sovice sněžní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost: 'Podestýlku v hnízdě vytváří ze zbytků kořisti a vývržků.',
        obrazek: sycRousnyObr,
      },
    },
    {
      otazka: 'Víš co je to za zvíře?',
      typ: 'zvuk',
      soubor: strakaObecnaMp3,
      odpovedi: [
        {
          nazev: 'Straka obecná',
          spravne: true,
        },
        {
          nazev: 'Vrána obecná',
          spravne: false,
        },
        {
          nazev: 'Tetřev hlušec',
          spravne: false,
        },
        {
          nazev: 'Sluka lesní',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Velikostí svého hnízda, tedy množstvím přineseného materiálu, samec zřejmě dokazuje samici, kolik úsilí je ochoten a schopen vložit do vlastního hnízdění. V mohutných hnízdech pak samičky snášejí více vajíček. Čím více tedy samec investuje do bydlení, tím více má potomků.',
        obrazek: strakaObecnaObr,
      },
    },
  ],
  [
    {
      otazka: 'Komu patří tento hlas?',
      typ: 'zvuk',
      soubor: jezevecLesniMp3,
      odpovedi: [
        {
          nazev: 'Jezevec lesní',
          spravne: true,
        },
        {
          nazev: 'Ježek západní',
          spravne: false,
        },
        {
          nazev: 'Medvěd hnědý',
          spravne: false,
        },
        {
          nazev: 'Rys ostrovid',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Jezevci si v podzemí vytváří vlastní labyrint. Jejich chodby mají průměr až čtvrt metru, sahají do hloubky pěti metrů a kříží se nad sebou v několika patrech.',
        obrazek: jezevecLesniObr,
      },
    },
    {
      otazka: 'Komu patří tento hlas?',
      typ: 'zvuk',
      soubor: jezekZapadniMp3,
      odpovedi: [
        {
          nazev: 'Ježek západní',
          spravne: true,
        },
        {
          nazev: 'Psík mývalovitý',
          spravne: false,
        },
        {
          nazev: 'Veverka obecná',
          spravne: false,
        },
        {
          nazev: 'Sysel obecný',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Často si pletete západního a východního ježka? Zde je malá nápověda. Ježkovi západnímu rostou ostny dozadu a na prsou má hnědou srst, východní má bílou náprsenku a ostny „rozcuchané“. ',
        obrazek: jezekZapadniObr,
      },
    },
    {
      otazka: 'Komu patří tento hlas?',
      typ: 'zvuk',
      soubor: rysOstrovidMp3,
      odpovedi: [
        {
          nazev: 'Rys ostrovid',
          spravne: true,
        },
        {
          nazev: 'Medvěd hnědý',
          spravne: false,
        },
        {
          nazev: 'Zubr evropský',
          spravne: false,
        },
        {
          nazev: 'Medvěd ušatý',
          spravne: false,
        },
      ],
      odmena: {
        zajimavost:
          'Rys není žádný vytrvalý pronásledovatel, na kořist číhá, či se k ní nepozorovaně přiblíží a útočí z bezprostřední blízkosti. Pokud ji nedostihne několika skoky, nechá ji být (většinou již po několika desítkách metrů, maximálně po cca 100 metrech).',
        obrazek: rysOstrovidObr,
      },
    },
  ],
];

export const collection = [];
questions.forEach((level, levelIndex) => {
  level.forEach((question, questionIndex) => {
    collection.push({
      titulek: question.odpovedi
        .filter((odpoved) => {
          return odpoved.spravne;
        })
        .pop().nazev,
      zajimavost: question.odmena.zajimavost,
      obrazek: question.odmena.obrazek,
      zvuk: question.soubor,
      level: levelIndex,
      index: questionIndex,
    });
  });
});
